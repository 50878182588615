<!--
 * @Author: wjc
 * @Date: 2023-11-15 17:31:09
 * @LastEditors: wjc
 * @LastEditTime: 2023-12-04 19:38:29
 * @Description: 
-->
<template>
  <div>
    <MiniTitle :title="name" icon="djyl" bg="#FF0000" :to="`/content/${classId2}`"></MiniTitle>
    <template v-for="item in list" :key="item.id">
      <MLinkItem
        :text="item.name"
        :to="`/content/post/${item.id}?m=${item.classId2}`"
        :jump="item.jumpLink"
        :date="item.pushTime"
        bg="#FF0000"
      ></MLinkItem>
    </template>
    <el-empty v-if="list.length === 0" description="暂无相关内容" />
  </div>
</template>

<script setup lang="ts">
  import { useOptionStore } from '~/stores/modules/option'
  import { getInfoList } from '~/api/activity/info'

  defineOptions({
    name: 'Rules',
  })

  const optionStore = useOptionStore()
  const name = ref('党建引领')
  const list = ref([])
  const current = computed(() => optionStore.menus.find((item) => item.name === name.value))
  const classId1 = computed(() => {
    if (current.value) {
      return current.value.id
    } else {
      return '912305321901290600'
    }
  })
  const classId2 = computed(() => {
    if (current.value) {
      const c = current.value.children[0]
      return c.id
    } else {
      return '912305321901290601'
    }
  })

  getInfoList({
    pageNum: 1,
    pageSize: 6,
    isPage: true,
    classId1: classId1.value,
  }).then((res) => {
    if (res.data && res.data.list) {
      list.value = res.data.list
    }
  })
</script>

<style scoped lang="scss"></style>
